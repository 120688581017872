/**
 * Add any environment variables you need here
 */
 export default {
  COMPANY_NAME: 'The Flowery',
  STORE_URL: 'https://api.theflowery.co',
  PLATFORM_URL: 'https://app.getsalve.co',
  SALVE_TOKEN: '824992|N3NEMESOeQnwZrpI5lsCpg96D5btKtpYgpEgu3W2d2b75bd0',
  APP_ENV: 'production',
  GOOGLE_TOKEN: 'G-Q8XNDVG5XJ',
  GOOGLE_APP_NAME: 'Flowery eCommerce',
  ZENDESK_TOKEN: '8a2271ef-dfac-4682-bd5f-236439ce108f',
  STORE_ROUTES: {
    // Fetches data for the navbar and footer
    NAVIGATION_DATA: '/wp-json/acf/v3/options/options',
  },
  STORE_FEATURES: {
    IN_STORE_PICKUP: true,
  },
  IMAGE_KIT_URL: 'https://ik.imagekit.io/salve/',
  STORE_CONFIG: {
    EMPLOYEE_ONLY_PICKUP_LOCATIONS: [2],
    DEFAULT_LOCATION_ID: 2,
    HOMESTEAD_ID: 2,
    FARM_EMPLOYEE_STORE_ID: 71,
    HOMESTEAD_EMPLOYEE_STORE: 'HES',
    FARM_EMPLOYEE_STORE: 'FES',
  },
  SENTRY_DSN: 'https://13c0566f6902e687ddb7c516db22f8b4@o1103062.ingest.us.sentry.io/4508740499734529',
  SENTRY_AUTH_TOKEN: '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',
};
