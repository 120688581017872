export default defineNuxtPlugin(() => {
  if (process.client) {
    window.dataLayer = window.dataLayer || []

    const originalPush = window.dataLayer.push

    window.dataLayer.push = function (...args) {
      console.log('dataLayer.push called with:', args)

      for (const arg of args) {
        if (arg == null) {
          console.warn('🚨 Invalid dataLayer push! null/undefined:', arg)
        }
      }

      return originalPush.apply(this, args)
    }
  }
})
