import environment from '@/environment'

export const ENUM_ACQUISITION_METHODS = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup'
}

export default {
  namespaced: true,
  state: {},

  getters: {
    xSelectedAddressId(state, getters, rootState, rootGetters) {
      const { id, method } = rootGetters['authuser/xCurrentUserPreferences']?.purchase || {}
      if (id && method === ENUM_ACQUISITION_METHODS.DELIVERY) {
        return id
      }
      return null
    },

    xSelectedPickupLocationId(state, getters, rootState, rootGetters) {
      const { id, method } = rootGetters['authuser/xCurrentUserPreferences']?.purchase || {}
      if (id && method === ENUM_ACQUISITION_METHODS.PICKUP) {
        return parseInt(id)
      }
      return null
    },

    xSelectedAcquisitionMethod(state, getters, rootState, rootGetters) {
      const { method } = rootGetters['authuser/xCurrentUserPreferences']?.purchase || {}
      return method || ENUM_ACQUISITION_METHODS.DELIVERY
    },

    /**
     * Returns the currently selected address selected by the current user.
     * Users can have multiple addresses to ship product on their accounts. This returns the currently selected one
     */
    xSelectedAddress(state, getters, rootState, rootGetters) {
      const list = rootGetters['authuser/xGetAddressList']

      if (list.length < 1) return null
      if (!getters.xSelectedAddressId) return null

      return list.find((item) => item.address_id === getters.xSelectedAddressId) || null
    },

    xSelectedPickupLocation(state, getters, rootState, rootGetters) {
      const list = rootGetters['regions/xPickupLocations']
      if (list.length < 1) return null
      return list.find((item) => item.id === getters.xSelectedPickupLocationId) || null
    },

    xSelectedLocationId(state, getters) {
      if (!getters.xSelectedAddress && !getters.xSelectedPickupLocation) {
        return environment.STORE_CONFIG.DEFAULT_LOCATION_ID
      }

      const locationId = getters.xDeliverySelected
        ? parseInt(getters.xSelectedAddress?.location_id)
        : getters.xSelectedPickupLocation?.id

      if (!locationId) {
        return environment.STORE_CONFIG.DEFAULT_LOCATION_ID
      }

      return locationId
    },

    xDeliverySelected(state, getters) {
      return getters.xSelectedAcquisitionMethod === ENUM_ACQUISITION_METHODS.DELIVERY
    },

    xPickupSelected(state, getters) {
      return getters.xSelectedAcquisitionMethod === ENUM_ACQUISITION_METHODS.PICKUP
    },
    xSelectedBmStore(state, getters, rootState, rootGetters) {
      const list = rootGetters['regions/xMortarStores']
      if (list.length < 1) return null
      return list.find((item) => item.id === getters.xSelectedMortarStoreId) || null
    }
  },

  actions: {
    xSelectNewLocation({ dispatch }, addressID) {
      dispatch(
        'authuser/xUpdatePurchasePreferences',
        { id: addressID, method: ENUM_ACQUISITION_METHODS.DELIVERY },
        { root: true }
      )
    },
    xSelectNewPickupLocation({ dispatch }, locationID) {
      dispatch(
        'authuser/xUpdatePurchasePreferences',
        { id: parseInt(locationID), method: ENUM_ACQUISITION_METHODS.PICKUP },
        { root: true }
      )
    }
  }
}
