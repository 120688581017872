export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"},{"name":"format-detection","content":"telephone=no"},{"name":"apple-itunes-app","content":"app-id=6451003120"}],"link":[{"rel":"preload","as":"style","href":"https://fonts.googleapis.com/css?family=DM+Mono:400,500&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css?family=DM+Mono:400,500&display=swap","media":"print","onload":"this.media='all'"},{"rel":"icon","type":"image/x-icon","href":"/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"The Flowery","htmlAttrs":{"lang":"en"},"bodyAttrs":{"class":"font-main"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"