import { defineNuxtPlugin } from '#imports'
import VueGtag from 'vue-gtag'
import { nextTick } from 'vue'
import environment from '@/environment'

export default defineNuxtPlugin((nuxtApp) => {
  const token = environment?.GOOGLE_TOKEN
  if (!token) {
    console.warn('Google Analytics token is missing!')
    return
  }

  // Ensure window.dataLayer exists
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  const options = {
    config: {
      id: token
    }
  }

  // The app name (required to enable screen view)
  const appName = environment?.GOOGLE_APP_NAME
  if (appName) {
    options.config.pageTrackerScreenviewEnabled = true
    options.config.appName = appName
  }

  // Ensure router exists before initializing vue-gtag
  if (!nuxtApp.$router) {
    console.error('Vue Router is missing. Vue-Gtag will not track navigation.')
    return
  }

  // Use VueGtag after Nuxt is fully initialized
  nextTick(() => {
    nuxtApp.vueApp.use(VueGtag, options, nuxtApp.$router)
  })
})
